/*
 * @Author: 朱璐
 * @Date: 2022-11-12 15:44:29
 * @Description: 错误组件
 */
import { classNames } from '@common/application/utils/classnames';
import { createPath } from '@common/application/utils/create-path';
import { encode } from '@common/application/utils/seo';
import { Button } from '@common/components/common/button';
import { DImage } from '@common/components/common/image';
import { ROUTE_MAP } from '@common/constants/route-map';
import Head from 'next/head';
import NextRouter from 'next/router';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';

import { CustomLayout } from '@/components/common/custom-layout';
import { IMG_ICON_NETWORK, IMG_ICON_NOT_FOUND } from '@/resources/images';

export const Exceptional = ({
  message = '',
  statusCode = 500,
  image = '',
  hideButton = false
}: {
  message?: string;
  statusCode?: number;
  image?: string;
  hideButton?: boolean;
}) => {
  const { t } = useTranslation(['common']);
  const title = useMemo(() => {
    if (message) return message;
    // eslint-disable-next-line sonarjs/no-small-switch
    switch (statusCode) {
      case 500:
      case 501:
        return t('exceptional.wrong');
      default:
        return t('pageNotFound.title');
    }
  }, [statusCode]);

  const ButtonComp = useMemo(() => {
    if (hideButton) return null;
    // eslint-disable-next-line sonarjs/no-small-switch
    switch (statusCode) {
      case 500:
        return (
          <Button
            className="min-w-38 w-fit px-4 h-11 mt-6 !bg-primary text-white"
            onClick={() => {
              NextRouter.reload();
            }}
          >
            <span className={classNames('uppercase', 'text-xs', 'pc:text-sm')}>
              {t('exceptional.backToHome')}
            </span>
          </Button>
        );
      default:
        return (
          <Button
            className="min-w-38 w-fit px-4 h-9 mt-6 !bg-primary text-white"
            onClick={() => {
              window.location.href = createPath(ROUTE_MAP.HOME, {}, { withLocale: true });
            }}
          >
            <span className={classNames('uppercase', 'text-xs', 'xl:text-base')}>
              {t('pageNotFound.backToHome')}
            </span>
          </Button>
        );
    }
  }, [statusCode]);

  const ImgSrc = useMemo(() => {
    if (image) return image;
    // eslint-disable-next-line sonarjs/no-small-switch
    switch (statusCode) {
      case 500:
      case 501:
        return IMG_ICON_NETWORK;
      default:
        return IMG_ICON_NOT_FOUND;
    }
  }, [statusCode]);
  return (
    <CustomLayout>
      <Head>
        <title>
          {t('common:pageTitle.error', {
            error: decodeURIComponent(encode(title))
          })}
        </title>
      </Head>
      <div className="container mx-auto pc:py-6 py-4">
        <div className="bg-white h-100 flex items-center flex-col">
          <DImage src={ImgSrc} fill className="relative w-75 min-h-42.5 h-fit mt-27" priority />
          <p className={classNames('text-black-1 text-base mt-6')}>{t(title)}</p>
          {ButtonComp}
        </div>
      </div>
    </CustomLayout>
  );
};
