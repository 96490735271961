/*
 * @Author: 朱璐
 * @Date: 2022-10-28 11:12:47
 * @Description: Footer
 */

import { useBreakpoint } from '@common/application/hooks/use-breakpoint';
import { useInRN } from '@common/application/hooks/use-in-rn';
import { useTranslationNode } from '@common/application/hooks/use-translation-node';
import { classNames } from '@common/application/utils/classnames';
import { DImage } from '@common/components/common/image';
import LinkOrDiv from '@common/components/common/link-or-div';
import { cloneDeep } from 'lodash-es';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';

import { FloatingButtons } from '@/components/common/floating-buttons';
import { GlobalConfig } from '@/constants/global-config';
import { IMG_FOOTER, IMG_FOOTER_MOBILE, IMG_LOGO } from '@/resources/images';
import { FeatureIcon } from '@/resources/sprites-images/feature-icon';
import { FooterIcon } from '@/resources/sprites-images/footer-icon';
import { PayIcon } from '@/resources/sprites-images/pay-icon';

interface Props {
  children?: React.ReactNode;
}

const LogoContainer = () => {
  const { t } = useTranslation(['common']);

  const NAMES_LIST = [
    { id: 1, name: t('thoughtfulService'), icon: 'service' },
    { id: 2, name: t('authenticGuarantee'), icon: 'quality' },
    { id: 3, name: t('freeShipping'), icon: 'material' },
    { id: 4, name: t('shoppingGuarantee'), icon: 'exchange' },
    { id: 5, name: t('airConditioner'), icon: 'code' },
    { id: 6, name: t('designatedProduct'), icon: 'stages' }
  ];

  return (
    <div className={classNames('border-b border-black-6', 'pc:mt-12.5 pc:border-none mb-0')}>
      <div className={classNames('flex items-center justify-center', 'pb-4', 'pc:py-0')}>
        <div
          className={classNames(
            'flex flex-wrap items-start gap-y-4',
            'gap-x-7',
            'pc:gap-x-6 pc:items-center'
          )}
        >
          {NAMES_LIST.map((item) => (
            <section
              className={classNames(
                'flex flex-col items-center',
                'w-16 min-h-19',
                'pc:w-22.5 pc:h-26.5'
              )}
              key={item.id}
            >
              <div
                className={classNames(
                  'flex justify-center items-center w-10 h-10 mb-2 bg-black-9 rounded-full shrink-0',
                  'pc:w-14 pc:h-14 pc:mb-2.5'
                )}
              >
                <FeatureIcon className="w-6 h-6 pc:w-8 pc:h-8" icon={item.icon} />
              </div>
              <div className={classNames('text-black-1 text-center', 'text-xs', 'pc:text-sm')}>
                {item.name}
              </div>
            </section>
          ))}
        </div>
      </div>

      <div
        className={classNames(
          'h-0.25',
          'border-0',
          'pc:border-b pc:w-full pc:border-white pc:opacity-10'
        )}
      ></div>
    </div>
  );
};

const MobileLink: React.FC<Props> = ({ children }) => {
  const [expand, setExpand] = useState(new Set());
  const { t } = useTranslation(['common']);

  const { footerLink: link } = GlobalConfig();
  return (
    <div className="relative w-full container mx-auto mb-4">
      <div className="w-full">
        {link.map((item, index) => (
          <div
            key={index}
            className={classNames(
              'w-full flex flex-col text-black-1 text-xs border-b border-black-6'
            )}
          >
            <div
              className="flex items-center justify-between py-5"
              onClick={() => {
                if (expand.has(index)) {
                  setExpand((org) => {
                    const _org = cloneDeep(org);
                    _org.delete(index);
                    return _org;
                  });
                } else {
                  setExpand((org) => {
                    const _org = cloneDeep(org);
                    _org.add(index);
                    return _org;
                  });
                }
              }}
            >
              <span>{t(item.title)}</span>
              <FooterIcon className="w-6 h-6" icon={expand.has(index) ? 'close' : 'open'} />
            </div>
            {expand.has(index) &&
              item.list.map((item, index) => (
                <LinkOrDiv href={item.href} key={`${item.text}-${index}`}>
                  <div className="mb-3 pl-4 text-black-3 text-xs cursor-pointer">
                    {t(item.text)}
                  </div>
                </LinkOrDiv>
              ))}
          </div>
        ))}
      </div>
      {children}
    </div>
  );
};

const WebLink: React.FC<Props> = () => {
  const { t } = useTranslation(['common']);
  const { footerLink: link } = GlobalConfig();
  return (
    <div className="relative mt-12.5">
      <div className="flex justify-between">
        {link.map((item, index) => (
          <div
            key={index}
            className={classNames(
              'flex flex-col text-black-1 text-base',
              'pc:pr-8 pc:pl-7.5 pc:border-l pc:border-black-7 pc:first:border-none pc:first:pl-0'
            )}
          >
            <div
              className={classNames('flex items-center mb-3.75 cursor-pointer font-medium', {
                'justify-center': index !== 0
              })}
            >
              <span>{t(item.title)}</span>
            </div>
            {item.list.map((item, i) => (
              <LinkOrDiv key={`${item.text}-${i}`} href={item.href}>
                <div
                  className={classNames('mt-2.5 text-black-3 text-sm cursor-pointer', {
                    'text-center': index !== 0
                  })}
                >
                  {t(item.text)}
                </div>
              </LinkOrDiv>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

/**
 * 酒类以及支付卡片展示
 */
const CommonEle = () => {
  const { footerPayLogo } = GlobalConfig();
  return (
    <div className={classNames('grid grid-cols-4 gap-y-2 mb-1', 'pc:grid-cols-5')}>
      {footerPayLogo.map((item, index) => (
        <div key={index} className={classNames('flex justify-center items-center')}>
          <LinkOrDiv href={item.href} key={index}>
            <div className={classNames('w-15.75 h-11 flex-shrink-0', 'pc:w-15 pc:h-10.5')}>
              <PayIcon
                className="w-full h-full rounded-sm  pc:hidden"
                aspectRatio="15.75/11"
                icon={item.icon}
              />
              <PayIcon
                className="hidden pc:block w-full h-full rounded-sm"
                aspectRatio="15/10.5"
                icon={item.icon}
              />
            </div>
          </LinkOrDiv>
        </div>
      ))}
    </div>
  );
};

const Subscribe = () => {
  const { mobile } = useBreakpoint();
  const tNode = useTranslationNode();
  const { t } = useTranslation(['common']);

  return (
    <div className={classNames('bg-black-9 h-32.5', 'pc:h-17 pc:px-30')}>
      <div
        className={classNames(
          'flex h-full flex-col justify-center items-center bg-no-repeat',
          'pc:flex-row'
        )}
        style={{
          backgroundImage: `url(${
            mobile ? IMG_FOOTER_MOBILE.default.src : IMG_FOOTER.default.src
          })`,
          backgroundSize: 'auto 100%'
        }}
      >
        <DImage className={classNames('w-17.5 h-4.5', 'pc:mr-3')} src={IMG_LOGO} />
        <div
          className={classNames('w-4 h-0.25 mt-3 bg-black-1', 'pc:mt-0 pc:mr-3 pc:w-7.5 ')}
        ></div>
        <div
          className={classNames(
            'mt-3 text-2xs text-center px-2',
            'pc:mt-0 pc:mr-3 pc:text-lg  pc:px-0'
          )}
        >
          {tNode('subscribe.title', {
            name: <span className="font-medium">「{t('subscribe.target')}」</span>
          })}
        </div>
        {/* 根据需求，暂时隐藏 */}
        {/* <div
          className={classNames(
            'bg-ahaa-color  mt-4 w-22 h-6.5 rounded-full text-5xs text-white flex justify-center items-center',
            'pc:text-sm pc:mt-0'
          )}
        >
          <span className="mr-1.5">+</span>
          <span>{t('subscribe.btn')}</span>
        </div> */}
      </div>
    </div>
  );
};
export const Footer = ({
  className,
  floatingButtonClassName
}: {
  className?: string;
  floatingButtonClassName?: string;
}) => {
  const { t } = useTranslation(['common']);
  const isRnWebViewState = useInRN();

  return (
    <footer className={classNames('relative bg-white w-full ', className)}>
      {!isRnWebViewState && (
        <>
          <div className="pc:block mobile:hidden">
            <Subscribe />
            <div className="container">
              <div className=" justify-between flex border-b border-black-7 pb-12.5">
                <WebLink />
                <LogoContainer />
              </div>
            </div>
          </div>

          <div className="pc:hidden mobile:block">
            <Subscribe />
            <MobileLink />
            <div className="container">
              <LogoContainer />
            </div>
          </div>

          <div className="container">
            <div className={classNames('h-0.25   border-b border-white')}></div>
            <div
              className={classNames(
                'text-black-4 container block text-center mt-4',
                'text-4xs',
                'pc:mt-0  pc:px-0 pc:py-8 pc:text-xs pc:flex pc:justify-between pc:items-center'
              )}
            >
              <span className={classNames('py-4', 'pc:py-0')}>{t('login.footText.copyright')}</span>
              <CommonEle />
            </div>
          </div>
        </>
      )}

      <FloatingButtons className={floatingButtonClassName} />
    </footer>
  );
};
