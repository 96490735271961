import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { classNames } from '@common/application/utils/classnames';
import { IS_CLIENT } from '@common/constants/common';
import { createRoot } from 'react-dom/client';
import { DImage } from '../image';
export function Loading(props) {
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: classNames('fixed w-full h-full left-0 top-0 right-0 bottom-0'), style: { zIndex: 2000 } }, { children: [props.mask && (_jsx("div", { className: classNames('absolute w-full h-full left-0 top-0 bg-black bg-opacity-50 mask') })), _jsx("div", Object.assign({ className: classNames('absolute rounded-lg flex justify-center items-center z-40 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 imgWrapper bg-white', 'w-25 h-25', 'xl:w-35 xl:h-35') }, { children: _jsx(DImage, { className: "w-20 h-20", src: props.image, alt: "" }) }))] })), _jsx("style", Object.assign({ jsx: true }, { children: `
        .container {
          z-index: 1000;
          overflow: hidden;
        }
      ` }))] }));
}
let div = null;
// loading 状态计数器
// 用于防止同时触发多次 loading 时，loading 状态被第一次的 loading 结束取消。
// 大于 0 时显示
let loadingCount = 0;
let root = null;
Loading.show = (props) => {
    if (!IS_CLIENT)
        return;
    // 等于 0 时调用创建 loading
    if (loadingCount === 0) {
        div = document.createElement('div');
        div.id = 'loading';
        document.body.appendChild(div);
        document.body.classList.add('disable-page-scroll');
        setTimeout(() => {
            root = createRoot(document.getElementById('loading'));
            root.render(_jsx(Loading, Object.assign({}, props)));
        });
    }
    // 每次调用都加 1
    loadingCount += 1;
};
Loading.hide = () => {
    if (!IS_CLIENT)
        return;
    // 延时调用，避免闪屏
    setTimeout(() => {
        if (loadingCount > 0) {
            // 每次调用都减 1
            loadingCount -= 1;
            // 减 1 后等于 0 则销毁 loading
            if ((div === null || div === void 0 ? void 0 : div.parentNode) && loadingCount === 0) {
                div.parentNode.removeChild(div);
                document.body.classList.remove('disable-page-scroll');
                div = null;
            }
        }
    }, 100);
};
Loading.defaultProps = {
    mask: true,
    image: ''
};
