/*
 * @Author: 朱璐
 * @Date: 2022-11-01 14:39:20
 * @Description: 滚动到顶部
 */

import { classNames } from '@common/application/utils/classnames';
import { scrollTop } from '@common/application/utils/scroll-top';
import { Button } from '@common/components/common/button';
import { throttle } from 'lodash-es';
import React, { useEffect, useState } from 'react';

import { BlackLineIcon } from '@/resources/sprites-images/black-line-icon';

export const ScrollToTop: React.FC<React.PropsWithCustomStyle> = () => {
  const [showBackToTop, setBackToTop] = useState(false);
  useEffect(() => {
    window.addEventListener(
      'scroll',
      throttle(() => {
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        setBackToTop(scrollTop >= 500);
      }, 200)
    );
  }, []);
  const scrollToTop = () => {
    scrollTop();
  };

  return (
    <>
      {showBackToTop ? (
        <Button
          className={classNames(
            'relative px-0 py-0 rounded-xl border-none bg-white opacity-90  shadow-back-top flex flex-col',
            'my-2 w-10 h-10',
            'pc:my-4 pc:w-15 pc:h-15'
          )}
          icon={<BlackLineIcon className={classNames('w-6 h-6')} icon="back-top" />}
          onClick={scrollToTop}
        >
          <span className={classNames('text-black-1 text-xs', 'hidden', 'pc:block')}>TOP</span>
        </Button>
      ) : null}
    </>
  );
};
