import { classNames } from '@common/application/utils/classnames';

import { ScrollToTop } from './scroll-to-top';

export const FloatingButtons = ({ className }: { className?: string }) => {
  return (
    <div
      className={classNames(
        'fixed bottom-30 z-2 cursor-pointer',
        'right-2.75',
        'pc:right-10',
        className
      )}
    >
      <ScrollToTop className="static" />
      {/* <Button
        className="hidden pc:block relative my-2 px-0 py-0 rounded-full border-none bg-transparent pc:my-4"
        textClassName={classNames(
          'absolute top-0 right-0 translate-x-1/4 -translate-y-1/4 flex items-center justify-center px-1 min-w-4 h-4 text-xs text-white bg-primary rounded-full',
          {
            hidden: !count
          }
        )}
        icon={<ColorOutlineIcon className="w-7 h-7 pc:w-10 pc:h-10" icon="cart" />}
        href={ROUTE_MAP.CART}
      >
        {count}
      </Button> */}
    </div>
  );
};
